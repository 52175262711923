var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: {
        title: _vm.modalConfig.title,
        visible: _vm.modalConfig.visible,
        width: _vm.modalConfig.width || "1000px",
        destroyOnClose: true,
        maskClosable: false,
        centered: "",
        footer: null,
        zIndex: 3000,
      },
      on: { cancel: _vm.close },
    },
    [
      _c(
        "el-scrollbar",
        {
          staticClass: "dialog-style",
          style: { height: _vm.modalConfig.height || "60vh" },
          attrs: {
            "wrap-class": "modal-scrollbar",
            "view-class": "modal-container",
            native: false,
            noresize: false,
            tag: "div",
          },
        },
        [
          _c("upload-table", {
            ref: "fileTable",
            attrs: { value: _vm.files, disabled: _vm.disabled, tips: _vm.tips },
            on: { onGetFileList: _vm.onGetFileList },
          }),
          _c(
            "div",
            { staticClass: "dialog-footer" },
            [
              _c(
                "el-button",
                {
                  attrs: { icon: "el-icon-close", type: "danger" },
                  on: { click: _vm.close },
                },
                [_vm._v("关闭")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }