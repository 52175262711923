<template>
  <a-modal
    :title="modalConfig.title"
    :visible="modalConfig.visible"
    :width="modalConfig.width||'1000px'"
    :destroyOnClose="true"
    :maskClosable="false"
    centered
    :footer="null"
    :zIndex='3000'
    @cancel="close"
  >
    <el-scrollbar
      class="dialog-style"
      wrap-class="modal-scrollbar"
      view-class="modal-container"
      :style="{height: modalConfig.height || '60vh'}"
      :native="false"
      :noresize="false"
      tag="div"
    >
      <upload-table ref="fileTable" @onGetFileList="onGetFileList" :value="files" :disabled="disabled" :tips='tips'></upload-table>
      <div class="dialog-footer">
        <el-button icon="el-icon-close" type="danger" @click="close">关闭</el-button>
      </div>
    </el-scrollbar>
  </a-modal>
</template>

<script>
import UploadTable from './upload_table.vue';

export default {
  components: {
    UploadTable,
  },
  props: {
    disabled: Boolean,
    tips: {
      type: Array,
      default: () => [],
    },
  },
  watch: {},
  data() {
    return {
      files: [],
      modalConfig: {
        title: '上传附件',
        visible: false,
      },
    };
  },
  methods: {
    onGetFileList(data) {
      this.files = data;

      this.$emit('onGetFileList', data);
    },
    // 打开弹窗
    openModal(data) {
      this.modalConfig = {
        ...this.modalConfig,
        ...{ visible: true },
      };
      console.log(data);

      this.files = data;
    },
    // 关闭弹窗
    close() {
      this.modalConfig = { ...this.modalConfig, visible: false };
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.ant-modal-body {
  padding: 0;
}

.dialog-style {
  padding-bottom: 73px;
}

/deep/.modal-container {
  height: 100%;
}

/deep/.modal-scrollbar {
  overflow-x: hidden;
  padding: 20px;
}
</style>
