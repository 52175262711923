import ProcessLog from './process_log/process_log.vue';
import Approve from './approve';
import TextView from './text_view';
import UploadModal from './upload_modal/upload.vue';

export default Approve;

export {
  ProcessLog, TextView, UploadModal,
};
